const ActionTypes = {
  ADD_CAR: "ADD_CAR",
  MODIFY_CAR: "MODIFY_CAR",
  REMOVE_CAR: "REMOVE_CAR",
  FETCH_CARS: "FETCH_CARS",
  CARS_REQUESTED: "CARS_REQUESTED",
  CARS_RECEIVED: "CARS_RECEIVED",
  CARS_ERROR: "CARS_ERROR",
  BOOKMARKED_REQUESTED: "BOOKMARKED_REQUESTED",
  BOOKMARKED_RECEIVED: "BOOKMARKED_RECEIVED",
  BOOKMARKED_ERROR: "BOOKMARKED_ERROR"
}

export default ActionTypes;